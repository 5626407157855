{
  "client": {
    "trackingCode": ""
  },
  "sharePriceGlobal": {
    "isin": "",
    "tidm": ""
  },
  "sharePriceHeader": {
    "label": "Share price:",
    "currency": "GBp",
    "timestampFormat": "kk:mm:ss",
    "sections": {
      "showDirectionArrow": true,
      "showChangePercent": true,
      "showChangeAmount": true,
      "showTimestamp": true,
      "showCurrency": true
    }
  },
  "sharePriceMini": {
    "currency": "GBp",
    "dateFormat": "hh:mmbbb d LLL yyyy",
    "sections": {
      "showDirectionArrow": true,
      "showHigh": true,
      "showLow": true,
      "showVolume": true,
      "showDate": true,
      "showCurrency": true
    }
  },
  "sharePriceTrades": {
    "dateFormat": "dd/MM/yyyy",
    "timeFormat": "kk:mm:ss"
  },
  "sharePriceDetailed": {
    "label": "Share price:",
    "currency": "GBp",
    "timestampFormat": "kk:mm OOO",
    "sections": {
      "showChart": false,
      "showTrades": false,
      "showDetail": true,
      "showHeader": true,
      "showOrdinaryShares": true
    }
  },
  "sharePriceInfo": {
    "currency": "GBp",
    "dateFormat": "dd MMM yyyy hh:mm:ss a"
  },
  "sharePrice": {
    "title": "Share price:",
    "miniTitle": "",
    "isin": "",
    "tidm": "",
    "currency": "GBp",
    "dateFormat": "hh:mmbbb d LLL yyyy",
    "trade": {
      "dateFormat": "dd/MM/yyyy",
      "timeFormat": "kk:mm:ss"
    }
  },
  "rnsGlobal": {
    "companyHash": ""
  },
  "rnsMini": {
    "numberOfResults": 2,
    "listOptions": {
      "dateFormat": "dd MMMM yyyy"
    }
  },
  "rnsList": {
    "generalSettings": {
      "filterByDateKeyword": "Filter by date/keyword",
      "filterByCategory": "Filter by category",
      "searchInputLabel": "Search term:",
      "dateFromInputLabel": "Date from:",
      "dateToInputLabel": "Date to:",
      "dateInputFormat": "dd-mm-yy"
    },
    "searchButton": {
      "buttonText": "Search",
      "textColor": "#ffffff",
      "backgroundColor": "#0000ff"
    },
    "listOptions": {
      "companyHashUsedForApi": "COMPANY HASH",
      "maxNumberOfResultsPerApiCall": "10",
      "dateFormat": "dd/MM/yyyy"
    }
  }
}
